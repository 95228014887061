@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    box-sizing: border-box;
}

.game-finish{
  text-shadow: 2px 2px 6px green, 4px 4px 8px grey;
}

.player-card{
  text-shadow: 1px 1px 4px rgb(22, 219, 22);
}